@import "smart-grid.less";

.some{
    .items{
        .row-float();

        .item{
			.col-float();
        }
   }
}

.profitItems{
    .row-ib();
    font-size: 0;
    text-align: center;
}

.profitItem{
    .col-ib();
}

.humans{ 
    .items{
        .row-ib();
        font-size: 0;
    	text-align: center;

        .item{
            .col-ib();
       }
   }
}