.some .items {
  margin-left: -15px;
  margin-right: -15px;
}

.some .items::after {
  display: block;
  content: "";
  clear: both;
}

.some .items .item {
  float: left;
}

.profitItems {
  margin-left: -15px;
  margin-right: -15px;
  font-size: 0;
  text-align: center;
}

.profitItem {
  display: inline-block;
  vertical-align: top;
}

.humans .items {
  margin-left: -15px;
  margin-right: -15px;
  font-size: 0;
  text-align: center;
}

.humans .items .item {
  display: inline-block;
  vertical-align: top;
}